import React, { Suspense } from 'react';
import './App.css';

// Routing components
import PublicRoute from './components/Routing/PublicRoute';
import PrivateRoute from './components/Routing/PrivateRoute';

// Toast components
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Loading components
import Loading from './pages/Loading';

// Routing
import { BrowserRouter as Router, Switch } from 'react-router-dom';

// Load i18n for internationalization
import './i18n/i18n';

// Pages to lazy render
// Login
const Login = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./pages/Login')), 1000);
  });
});

// Main
const Main = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./pages/Main')), 1000);
  });
});

// Method to check token on localstorage
const isAuthenticated = () => {
  // eslint-disable-next-line no-undef
  const token = localStorage.getItem('accessToken');
  try {
    if (token) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Switch>
          <PublicRoute isAuthenticated={isAuthenticated()} exact path="/login">
            {/* Public pages */}
            <Login />
          </PublicRoute>
          <PrivateRoute isAuthenticated={isAuthenticated()} path="/">
            {/* Private pages */}
            <Main />
          </PrivateRoute>
        </Switch>
        {/* Toast component */}
        <ToastContainer position="bottom-right" />
      </Router>
    </Suspense>
  );
}

export default App;
