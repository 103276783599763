import styled from 'styled-components';
// import colors from '../../styles/colors';

export const Container = styled.div`
  height: /utils/constants/fonts.scsss/constants/fonts.scsss/constants/fonts.scss;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  height: 39px;
  width: 39px;
  border-radius: 10px;
  object-fit: cover;
`;
