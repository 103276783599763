import { createSlice } from '@reduxjs/toolkit';

const inventoriesSlice = createSlice({
  name: 'inventories',
  initialState: {
    data: null,
    total_record_count: null,
    page_number: null,
    page_size: 5,
    total_pages: null
  },
  reducers: {
    getAllInventories: (state, action) => {
      state.data = action.payload.inventories;
      state.total_record_count = action.payload.total_record_count;
      state.page_number = action.payload.page_number;
      state.page_size = action.payload.page_size;
      state.total_pages = action.payload.total_pages;
    },
    removeInventory: (state, action) => {
      state.data = state.data.filter((item) => item._id != action.payload.id);
    }
  }
});

export const { removeInventory, getAllInventories } = inventoriesSlice.actions;
export default inventoriesSlice.reducer;
