import { createSlice } from '@reduxjs/toolkit';

const taxesSlice = createSlice({
  name: 'taxes',
  initialState: {
    data: null,
    total_record_count: null,
    page_number: null,
    page_size: 5,
    total_pages: null
  },
  reducers: {
    getAllTaxes: (state, action) => {
      state.data = action.payload.taxes;
      state.total_record_count = action.payload.total_record_count;
      state.page_number = action.payload.page_number;
      state.page_size = action.payload.page_size;
      state.total_pages = action.payload.total_pages;
    },
    removeTax: (state, action) => {
      state.data = state.data.filter((item) => item._id != action.payload.id);
    }
  }
});

export const { getAllTaxes, removeTax } = taxesSlice.actions;
export default taxesSlice.reducer;
