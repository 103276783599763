import { Route, Redirect } from 'react-router-dom';

import sidebarItems from '../../utils/helpers/routes.helper';

function PrivateRoute({ children, isAuthenticated, ...rest }) {
  const validRoute = sidebarItems.filter((item) => item.path === rest.path).length !== 0;
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          validRoute ? (
            children
          ) : (
            // eslint-disable-next-line react/react-in-jsx-scope
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
          )
        ) : (
          // eslint-disable-next-line react/react-in-jsx-scope
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
