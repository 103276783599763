import { createSlice } from '@reduxjs/toolkit';

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    data: null,
    total_record_count: null,
    page_number: null,
    page_size: 5,
    total_pages: null
  },
  reducers: {
    getAllProducts: (state, action) => {
      state.data = action.payload.products;
      state.total_record_count = action.payload.total_record_count;
      state.page_number = action.payload.page_number;
      state.page_size = action.payload.page_size;
      state.total_pages = action.payload.total_pages;
    },
    updateProducts: (state, action) => {
      state.Products.map((item) => {
        if (item._id === action.payload.id) {
          item.checked = action.payload.checked;
        }
        return item;
      });
    },
    removeProduct: (state, action) => {
      state.data = state.data.filter((item) => item._id != action.payload.id);
    }
  }
});

export const { getAllProducts, removeProduct } = productsSlice.actions;
export default productsSlice.reducer;
