import { Route, Redirect } from 'react-router-dom';

function PublicRoute({ children, isAuthenticated, ...rest }) {
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          // eslint-disable-next-line react/react-in-jsx-scope
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default PublicRoute;
