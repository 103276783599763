import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_AR } from './ar/common';
import { TRANSLATIONS_EN } from './en/common';
console.log('i18next-browser-languagedetector : ', LanguageDetector);
i18n.init({ lng: 'en-US' });
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: TRANSLATIONS_EN
    },
    ar: {
      translation: TRANSLATIONS_AR
    }
  }
});

export default i18n;
