export const TRANSLATIONS_AR = {
  global: {
    title: 'إيقل للفواتير',
    email: 'البريد الإلكتروني',
    password: 'كلمة السر',
    send: 'إرسال',
    dashboard: 'لوحة التحكم',
    providers: 'الموردون',
    sales: 'المبيعات',
    purchases: 'المشتريات',
    warehouse: 'المخزون',
    customers: 'العملاء',
    receipts: 'مذكرات الإيصالات',
    reports: 'التقارير',
    english: 'الإنجليزية',
    french: 'الفرنسية',
    arabic: 'العربية',
    configurations: 'الإعدادات',
    articles: 'المنتجات',
    unities: 'الوحدات',
    categories: 'الأصناف',
    taxes: 'الضرائب',
    inventory: 'الجرد',
    mouvements: 'حركات المنتجات',
    manageActions: 'إضافة تعديل حذف البياتات',
    parameters: 'تخصيصات',
    supplyChain: 'إدارة المخزن',
    warehouses: 'المخازن',
    image: 'الصورة',
    name: 'الإسم',
    address: 'العنوان',
    country: 'البلد',
    active: 'قيد الإستعمال',
    phone: 'رقم الهاتف',
    fax: 'رقم الفاكس',
    postalCode: 'الترقيم البريدي',
    website: 'الموقع الإلكتروني',
    remarks: 'الملاحظات',
    validate: 'حفظ'
  },
  login: {
    title: 'تسجيل الدخول',
    subtitle: 'تسجيل الدخول إلى حسابك',
    forgotPassword: 'نسيت كلمة السر'
  },
  forgotPassword: {
    title: 'نسيت كلمة السر',
    labelRadio: 'إرسال كلمة السر بواسطة'
  },
  successResetPassword: {
    title: 'نجاح تبديل كلمة السر',
    subtitle: 'لقد تم إرسال كلمة السر بنجاح'
  },
  purchases: {
    receipt: 'مذكرات الإيصالات',
    orders: 'الطلابات',
    invoices: 'فواتير الشراء',
    services: 'الخدمات',
    payments: 'المدفوعات',
    holdingTax: 'الضريبة المقتطعة'
  }
};
