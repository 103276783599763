import { createSlice } from '@reduxjs/toolkit';

const unitsSlice = createSlice({
  name: 'units',
  initialState: {
    data: null,
    total_record_count: null,
    page_number: null,
    page_size: 5,
    total_pages: null
  },
  reducers: {
    getAllUnits: (state, action) => {
      state.data = action.payload.units;
      state.total_record_count = action.payload.total_record_count;
      state.page_number = action.payload.page_number;
      state.page_size = action.payload.page_size;
      state.total_pages = action.payload.total_pages;
    },
    removeUnit: (state, action) => {
      state.data = state.data.filter((item) => item._id != action.payload.id);
    }
  }
});

export const { getAllUnits, removeUnit } = unitsSlice.actions;
export default unitsSlice.reducer;
