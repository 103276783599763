const sidebarItems = [
  {
    id: "home",
    label: "Base de données anonymisées",
    path: "/",
  },
  {
    id: "cart",
    label: "Cart",
    path: "/cart",
  },
  {
    id: "product",
    label: "Product",
    path: "/product/:id",
  },
  {
    id: "products",
    label: "Products",
    path: "/products",
  },
  {
    id: "wishlist",
    label: "Wishlist",
    path: "/wishlist",
  },
  {
    id: "contactus",
    label: "contactus",
    path: "/contactus",
  },
  {
    id: "aboutus",
    label: "aboutus",
    path: "/aboutus",
  },
];

export default sidebarItems;
