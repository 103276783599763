import { createSlice } from '@reduxjs/toolkit';

const providersSlice = createSlice({
  name: 'providers',
  initialState: {
    data: null,
    total_record_count: null,
    page_number: null,
    page_size: 5,
    total_pages: null
  },
  reducers: {
    getAllProviders: (state, action) => {
      state.data = action.payload.providers;
      state.total_record_count = action.payload.total_record_count;
      state.page_number = action.payload.page_number;
      state.page_size = action.payload.page_size;
      state.total_pages = action.payload.total_pages;
    },
    updateProviders: (state, action) => {
      state.providers.map((item) => {
        if (item._id === action.payload.id) {
          item.checked = action.payload.checked;
        }
        return item;
      });
    },
    removeProvider: (state, action) => {
      state.data = state.data.filter((item) => item._id != action.payload.id);
    }
  }
});

export const { getAllProviders, removeProvider } = providersSlice.actions;
export default providersSlice.reducer;
