import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isAuth: false,
    forgotPassword: false
  },
  reducers: {
    setUserSuccess: (state, action) => {
      state.user = action.payload.user;
      state.isAuth = true;
    },
    setUserFailure: (state) => {
      state.user = {};
      state.isAuth = false;
    },
    setForgotPassword: (state, action) => {
      state.forgotPassword = action.payload.forgotPassword;
    }
  }
});

export const { setUserSuccess, setUserFailure, setForgotPassword } = authSlice.actions;
export default authSlice.reducer;
