export const TRANSLATIONS_EN = {
  global: {
    title: 'Eagle Manufacturing',
    email: 'Email',
    password: 'Password',
    send: 'Send',
    dashboard: 'Dashboard',
    providers: 'Provider',
    sales: 'Sales',
    purchases: 'Purchases',
    warehouse: 'Warehouse',
    customers: 'Customers',
    receipts: 'Receipts notes',
    reports: 'Reports',
    english: 'English',
    french: 'French',
    arabic: 'Arabic',
    configurations: 'Configurations',
    articles: 'Articles',
    unities: 'Unities',
    categories: 'Categories',
    taxes: 'Taxes',
    inventory: 'Inventory',
    mouvements: 'Mouvements',
    manageActions: 'Add, Edit, Delete opérations',
    parameters: 'Parameters',
    supplyChain: 'Supply chain',
    warehouses: 'Warehouses',
    image: 'Image',
    name: 'Name',
    address: 'Address',
    country: 'Country',
    active: 'Active',
    phone: 'Phone',
    fax: 'Fax',
    postalCode: 'Postal code',
    website: 'Website',
    remarks: 'Remarks',
    validate: 'Validate'
  },
  login: {
    title: 'Sign in',
    subtitle: 'Sign in to you account'
  },
  forgotPassword: {
    title: 'Forgot password',
    labelRadio: 'Send your new password with :'
  },
  successResetPassword: {
    title: 'Success Reset password',
    subtitle: 'Your new password has been sent.'
  },
  purchases: {
    receipt: 'Receipt notes',
    orders: 'Purchase orders',
    invoices: 'Purchase invoices',
    services: 'Services',
    payments: 'Payments',
    holdingTax: 'Withholding tax'
  }
};
