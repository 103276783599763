import { configureStore, combineReducers } from '@reduxjs/toolkit';

import authReducer from './authRedux';
import providersReducer from './providersRedux';
import categoriesReducer from './categoriesRedux';
import unitsReducer from './unitsRedux';
import taxesReducer from './taxesRedux';
import warehousesReducer from './warehousesRedux';
import productsReducer from './productsRedux';
import inventoriesReducer from './inventoriesRedux';
import receiptNotesReducer from './receiptNotesRedux';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  version: 1,
  storage
};
const rootReducer = combineReducers({
  auth: authReducer,
  providers: providersReducer,
  categories: categoriesReducer,
  units: unitsReducer,
  taxes: taxesReducer,
  warehouses: warehousesReducer,
  products: productsReducer,
  inventories: inventoriesReducer,
  receiptNotes: receiptNotesReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export let persistor = persistStore(store);
